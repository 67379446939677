import React from "react";
import styled from "styled-components";
import "./App.css"; 

const Container = styled.div`
  color: #000;
  margin: 0;
  // margin: 1rem 1rem 1rem 1rem;
  // border: 1px solid #f5f5f5;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
`;

const Nav = styled.nav`
  display: flex;
  gap: 1rem;
`;

const NavItem = styled.a`
  text-decoration: none;
  color: #333;
  font-weight: 300;
  font-size: 1rem;
  &:hover {
    color: #0073e6;
  }
`;

// Hero section
const Hero = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: #FFF;
  color: #000;
  text-align: center;
`;

const HeroText = styled.div`
  max-width: 600px;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
`;

// Sections
const Section = styled.section`
  padding: 4rem 2rem;
  background-color: #f5f5f5; // #f9f9f9;
  &:nth-child(even) {
    background-color: #fff;
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  p {
    line-height: 1.5;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  a {
    // color: #000;
    // text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: #0073e6;
    }
  }
`;

// Footer
const Footer = styled.footer`
  padding: 1rem 2rem;
  text-align: center;
  background-color: #333;
  color: #fff;
  p {
    margin: 0;
  }
`;

export const ExperienceList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ExperienceItem = styled.li`
  margin-bottom: 2rem;
  h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0.3rem 0;
  }
`;

export const Testimonials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
`;

export const Testimonial = styled.div`
  background-color: #f5f5f5;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Quote = styled.p`
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 0.5rem;
`;

export const Author = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #0073e6;
  text-align: right;
`;

export const ContactDetails = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContactItem = styled.div`
  font-size: 1rem;
  strong {
    color: #000;
  }
  a {
    // text-decoration: none;
    // color: #333;
    &:hover {
      text-decoration: underline;
      color: #0073e6;
    }
  }
`;


const App = () => {
  return (
    <Container>
      <Header>
        <Logo>Mehdi Rachico</Logo>
        <Nav>
          {/* <NavItem href="#about">About</NavItem> */}
          {/* <NavItem href="#work">Work</NavItem> */}
          {/* <NavItem href="#testimonials">What people are saying</NavItem> */}
          <NavItem href="#contact">Contact</NavItem>
        </Nav>
      </Header>
      <Hero>
        <HeroText>
          <h1>Empowering Sustainable Choices Through Data</h1>
          <p>I help people make better decisions using data, for <a href="https://www.metrikflow.com" target="_blank" rel="noreferrer" style={{ color: "green" }}> a greener future</a></p>.
        </HeroText>
      </Hero>
      <Section id="about">
        <h2>ABOUT</h2>
        <p>
          Founder of <strong><a href="https://www.metrikflow.com" target="_blank" rel="noreferrer">Metrikflow</a></strong>, a SaaS venture that helps companies measure, track and reduce their carbon footprint and comply with overwhelming sustainability regulations.
        </p>
        <p>
          4+ years of experience building and optimizing software applications with a focus on backend technologies, API development, software architecture, data engineering and machine learning.
        </p>
        <p>
          I like people who are passionate about what they do and who are focused on solving problems rather than reacting to them.
        </p>


      </Section>
      <Section id="credo">
        <h2>CREDO</h2>
        <p>
        Gradatim Ferociter, which means "Step by step, Ferociously". I believe in taking small steps towards a goal, but with a relentless attitude.
        </p>
      </Section>
      <Section id="work">
        <h2>ORGANIZATIONS</h2>
        <ExperienceList>
          <ExperienceItem>
            <h3>Founder & CTO</h3>
            <p>Metrikflow | 2022–Present</p>
            {/* <p>
              Led teams to develop strategies for global businesses using
              analytics to unlock growth opportunities.
            </p> */}
          </ExperienceItem>
          <ExperienceItem>
            <h3>Software Engineer</h3>
            <p>Odoo | 2022–Present</p>
            {/* <p>
              Provided actionable insights to companies aiming to transform
              their digital presence with data-driven solutions.
            </p> */}
          </ExperienceItem>
          <ExperienceItem>
            <h3>Machine Learning Engineer</h3>
            <p>Forenamics | 2021-2022</p>
            {/* <p>
              Provided actionable insights to companies aiming to transform
              their digital presence with data-driven solutions.
            </p> */}
          </ExperienceItem>
          <ExperienceItem>
            <h3>Data Engineer</h3>
            <p>Wall Street Football | 2021</p>
            {/* <p>
              Provided actionable insights to companies aiming to transform
              their digital presence with software solutions.
            </p> */}
          </ExperienceItem>
          <ExperienceItem>
            <h3>Software Developer/Consultant</h3>
            <p>Independent | 2020–2021</p>
            {/* <p>
              Provided actionable insights to companies aiming to transform
              their digital presence with software solutions.
            </p> */}
          </ExperienceItem>
          <h2>COLLABORATIONS</h2>
          <ExperienceItem>
            <h3>Social Entrepreneur & Speaker</h3>
            <p>Enactus | 2018-2020</p>
            <p>
              Founded and bootstrapped a company for ecological solutions preventing catastrophic floods in rural areas.
            </p>
            <p>
              Pitched the project in startup funding competitions (We got the 3rd place in the "Finance in motion" competition)
            </p>
          </ExperienceItem>
          <h2>SIDE PROJECTS</h2>
          <ExperienceItem>
            <h3>Mental Health Monitor</h3>
            <p>Independent | 2020-2021</p>
            <p>
              Built and deployed a deep learning model designed to detect early signs of mental illness by analyzing user content on the social network Reddit, enabling proactive identification and intervention for individuals in need of support.
            </p>
          </ExperienceItem>
        <ExperienceItem>

        </ExperienceItem>
        </ExperienceList>
      </Section>
      <Section id="testimonials">
        <h2>DON'T TAKE MY WORD FOR IT</h2>
        <Testimonials>
          <Testimonial>
            <Quote>
              "Mehdi had a huge impact on Wall Street Football. He supported our Team of Data Scientists in several analytical tasks and at the same time he owned quite a few projects independently. Mehdi is very strong in term of technical skills, but at the same time he understands the business component very well and like to see the full picture. Mehdi has also very good communication ability, he is a smart and kind person and he is a great team-player. I am sure that he is going to have a very big impact in every project/company/team that he joins."
            </Quote>
            <Author>– Giovanni Bertoli - Co-founder & CEO at Wall Street Football | Ex-Googler</Author>
          </Testimonial>
          <Testimonial>
            <Quote>
              "Mehdi has proved himself as an exceptional analyst and data scientist. He helped Wall Street Football solve a range of technical issues relating to the infrastructure and base of our models. He has applied techniques in machine learning, data science software development and analysis as a means to achieving the business tasks set upon him. Apart from that it was really easy and straightforward to communicate with Mehdi as he was always showing a positive and open attitude during difficult tasks, involving technologies such as R, Python, MongoDB and Git. I would recommend Mehdi as a valuable member of any analytics team as he is able to effectively leverage his technical skills and communication style to get the job done."
            </Quote>
            <Author>– Yevgen Levin - Head of Data Engineering at WSF Odds</Author>
          </Testimonial>
          <Testimonial>
            <Quote>
              "I have been teaching Mehdi this year advanced techniques of deep learning techniques for images and graphs and he has shown assiduity, discipline, good learning skills and big interest in deep learing study field."
            </Quote>
            <Author>– Racha Friji - AI/ Quantum AI Researcher | PhD in AI |Techwomen Fellow 2022</Author>
          </Testimonial>
          <Testimonial>
            <Quote>
              "Mehdi is a great guy that I loved working with!"
            </Quote>
            <Author>– Milan Hofmann - Founder & CTO @ Stealth | Fellow bei Sigma Squared Society | Advisor-Tech @ BlueBurn</Author>
          </Testimonial>
        </Testimonials>
      </Section>
      <Section id="contact">
        <h2>CONTACT</h2>
        <p>
          Interested in working together?
        </p>
        <ContactDetails>
          <ContactItem>
            <strong>Email:</strong> <a href="mailto:mehdi@metrikflow.com">mehdi@metrikflow.com</a>
          </ContactItem>
          <ContactItem>
            <strong>LinkedIn:</strong>{" "}
            <a
              href="https://www.linkedin.com/in/mehdi-rachico"
              target="_blank"
              rel="noopener noreferrer"
            >
              @mehdi-rachico
            </a>
          </ContactItem>
          <ContactItem>
            <strong>Twitter:</strong>{" "}
            <a
              href="https://x.com/mehdirachico"
              target="_blank"
              rel="noopener noreferrer"
            >
              @mehdirachico
            </a>
          </ContactItem>
        </ContactDetails>
      </Section>
      <Footer>
        <p>© { new Date().getFullYear() } Mehdi Rachico. All rights reserved.</p>
      </Footer>
    </Container>
  );
};

export default App;
